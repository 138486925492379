import Turbolinks from "turbolinks";
import jQuery from "jquery";
import throttle from "lodash/throttle";
import { filesize } from "filesize/lib/filesize.es6";
import axios from "axios";

import { dragula } from "dragula";
import "dragula/dist/dragula.css";

import dt from "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons-dt/css/buttons.dataTables.css";

// Import TinyMCE
import tinymce from "tinymce/tinymce";

// Default icons are required for TinyMCE 5.3 or above
import "tinymce/icons/default";

// A theme is also required
import "tinymce/themes/silver";

// Any plugins you want to use has to be imported
import "tinymce/plugins/image";
import "tinymce/plugins/paste";
import "tinymce/plugins/link";
import "tinymce/plugins/autolink";
import "tinymce/plugins/lists";
import "tinymce/plugins/code";

axios.defaults.withCredentials = true;
axios.defaults.transformRequest = [
  (data, headers) => {
    data = data || new FormData();

    if (!data.has("csrf_token")) {
      data.set("csrf_token", document.body.dataset.csrfToken);
    }

    return data;
  },
];
axios.defaults.validateStatus = status => status < 500;
// axios.defaults.xsrfCookieName = 'csrftoken';
// axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';

import "./framework.plugged";
import "../css/style.css";
